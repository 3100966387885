/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Sentry = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "marketplace/sentry/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Sentry Integration',
      title: 'Integrate Sentry with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Sentry is an error monitoring tool that helps you detect bugs in your software. With
          Sentry, you can analyze all kinds of issues in one place, find out what caused them and
          come up with solutions faster.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>See how the users interacted with your code</li>
          <li>Find the reasons behind bugs</li>
          <li>Deliver solutions more effectively</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/sentry-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Sentry);

Sentry.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
